import React from 'react';
import styled from 'styled-components';
import Payment from '../../Components/Payment';

export default function PayPage(props) {
  return (
    <Wrapper>
      <div className="content">
        <Payment />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 80px;
  padding: 10px;

  & > .content {
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    max-width: 720px;
    background-color: white;
  }
`;
